import React, { useMemo } from 'react';
import { useQueryParam, StringParam } from "use-query-params";
import { Container, Heading, Box, Image, Spinner } from '@chakra-ui/react';
import { graphql } from 'gatsby';
import Main from '../../components/layouts/Main';
import usePrismicAboutUs from "../../hooks/usePrismicAboutUs";
import RichText from "../../components/PrismicRichText";

const Announcements = () => {
    const [id] = useQueryParam('id', StringParam);
    const data = usePrismicAboutUs();

    const mediator = useMemo(() => {
        const foundMediator = data?.mediators?.find(item => item.id === id);
        // console.log("Mediator found:", foundMediator);
        return foundMediator;
    }, [id, data]);

    if (!data) {
        return (
            <Main>
                <Container maxW="7xl" py="10" display="flex" justifyContent="center" alignItems="center">
                    <Spinner size="xl" />
                </Container>
            </Main>
        );
    }

    if (!mediator) {
        return (
            <Main>
                <Container maxW="7xl" py="10">
                    <Heading as="h1" fontSize="2xl"> </Heading>
                </Container>
            </Main>
        );
    }

    // console.log("Rendering mediator:", mediator);

    return (
        <Main>
            <Box overflow="hidden">
                <Container px="5" maxW="7xl">
                    <Heading
                        as="h1"
                        fontSize={{ base: '2xl', sm: "3xl" }}
                        fontWeight="900"
                        mt={{ base: '3', sm: "5" }}
                        mb={{ base: '1.875rem', sm: "10" }}>
                        {mediator.title}
                    </Heading>
                    <Image
                        h="200px"
                        w="210px"
                        objectFit="cover"
                        borderRadius="16px"
                        src={mediator.image}
                        alt={mediator.title}
                        // onError={(e) => {
                        //     console.error("Image failed to load:", e.target.src);
                        //     e.target.src = '/path/to/placeholder/image.jpg';
                        // }}
                    />
                    <Box fontSize="xl" mb="2rem">
                        <RichText text={mediator.description} />
                    </Box>
                    <Box mb="8rem" color="grey.100">
                        <RichText text={mediator.biography} />
                    </Box>
                </Container>
            </Box>
        </Main>
    );
}

export default Announcements;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;


//Od original version
// import React, {useMemo} from 'react'
// import { useQueryParam, StringParam } from "use-query-params";
// import { Container, Heading, Box, Image } from '@chakra-ui/react'
// import {graphql} from 'gatsby'
// import Main from '../../components/layouts/Main'
// import usePrismicAboutUs from "../../hooks/usePrismicAboutUs";
// import RichText from "../../components/PrismicRichText";

// const Announcements = () => {
//     const [id] = useQueryParam('id', StringParam)
//     const data = usePrismicAboutUs();
//     const mediators = useMemo(() => data.mediators.find(item => item.id === id), [id])

//     return(
//         <Main>
//             <Box overflow="hidden">
//                 <Container
//                     px="5"
//                     maxW="7xl">
//                     <Heading
//                         as="h1"
//                         fontSize={{base:'2xl', sm: "3xl"}}
//                         fontWeight="900"
//                         mt={{base: '3', sm: "5"}}
//                         mb={{base: '1.875rem', sm: "10"}}>
//                         {mediators?.title}
//                     </Heading>
//                     <Image
//                       h="200px"
//                       w="210px"
//                       objectFit="cover"
//                       borderRadius="16px"
//                       src={mediators?.image}
//                     />
//                     <Box fontSize="xl" mb="2rem"><RichText text={mediators?.description}/></Box>
//                     <Box mb="8rem" color="grey.100"><RichText text={mediators?.biography}/></Box>
//                 </Container>
//             </Box>
//         </Main>
//     )
// }

// export default Announcements

// export const query = graphql`
//   query ($language: String!) {
//     locales: allLocale(filter: {language: {eq: $language}}) {
//       edges {
//         node {
//           ns
//           data
//           language
//         }
//       }
//     }
//   }
// `